//
//
// user.scss
//

.date-nav {
  cursor: pointer;
  margin: 0 0.25em;
}

.reset-expansion-style {
  padding: 0 !important;
}

//Pager
.react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
}

.page-item.active .page-link {
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.page-item .page-link:hover {
  background-color: darken(#2c7be5, 10%);
  border-color: darken(#2c7be5, 10%);
}

.enabled-column {
  width: 50px;
}

.pointer {
  cursor: pointer;
}

.disabled-text {
  color: #c0c0c0;
}

/***************************************
* Board Visualisation
***************************************/

.boat-over {
  background-color: #748194;
}

.boat-out {
  background-color: black;
}

.boat-private {
  background-color: rgb(128, 128, 255);
}

.boat-full {
  background-color: $red;
}

.boat-open {
  background-color: #00d27a;
}

.boat-private.is-chinese,
.boat-open.is-chinese,
.boat-full.is-chinese {
  background-color: gold;
}

.boat-private.is-provisional,
.boat-open.is-provisional,
.boat-full.is-provisional {
  background-color: #ff007f;
}

.boat-private.tout-private,
.boat-open.tout-private,
.boat-full.tout-private {
  background-color: $blue;
}

.boat-private.is-imported,
.boat-open.is-imported,
.boat-full.is-imported {
  background-color: rgb(128, 128, 255);
}

.the-board {
  &.table tfoot > tr > th:first-child,
  &.table thead > tr > th:first-child,
  &.table tr td:first-child,
  &.table tr th:first-child {
    padding: 0;
    position: relative;
    width: 36px;
  }
}

.the-board td {
  position: relative;
  cursor: pointer;
}

.board-lanes > td {
  background: #f9fafd;
  padding-right: 0.5rem;
  font-size: 75%;
  text-align: center;
  font-weight: bold;
  border-top: none;
  padding-left: 20px;
}

.board-time {
  position: absolute;
  background: #f9fafd;
  padding-right: 0.5rem;
  font-size: 75%;
  top: -9px;
}

.board-time-round {
  font-weight: bold;
}

.board-card {
  width: 96%;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;
  opacity: 75%;
}

.board-card-60 {
  height: 96px;
}

.board-card-45 {
  height: 71px;
}

.board-card-90 {
  height: 146px;
}

.board-card-120 {
  height: 196px;
}

.board-notes {
  position: absolute;
  color: white;
  left: 3px;
  bottom: 0;
  font-size: 75%;
  opacity: 0.75;
}

.board-locked {
  position: absolute;
  color: white;
  right: 3px;
  font-size: 75%;
  opacity: 0.75;
}

.board-boat {
  position: absolute;
  color: white;
  right: 3px;
  bottom: 0;
  font-size: 75%;
  opacity: 0.75;
}

.board-people {
  min-height: 20px;
  color: white;
  position: absolute;
  font-size: 75%;
  left: 0;
  width: 100%;
  top: 0;
  font-weight: bold;
  //background: rgba(255, 255, 255, 0.25);
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  padding: 0.15rem 0.25rem 0;
}

.boat-over > .board-people {
  background: rgba(255, 255, 255, 0.25);
}

.boat-out > .board-people {
  background: rgba(255, 255, 255, 0.25);
}

.boat-open > .board-people {
  background: darken(#00d27a, 15%);
}

.boat-private > .board-people {
  background: #4244d0;
}

.boat-full > .board-people {
  background: darken($red, 15%);
}

.boat-open > .board-people {
  background: darken(#00d27a, 15%);
}

.is-chinese > .board-people {
  background: #deaf2b;
}

.is-provisional > .board-people {
  background: darken(#ff007f, 10%);
}

.tout-private > .board-people {
  background: darken($blue, 20%);
}

.is-imported > .board-people {
  background: #4244d0; // rgba(0, 0, 255, 0.25);
}

.board-indicators {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 150%;
  color: $white;
  opacity: 0.7;
}

.board-complete {
  opacity: 0.5;
}
.card-body-board {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}

.rdtPicker {
  width: 100% !important;
  td,
  th {
    height: 40px !important;
  }
}
